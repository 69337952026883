import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight1: '#FFFFFF',
      neutralLight2: '#FAF9F8',
      neutralLight3: '#EFECEA',
      neutralLight4: '#E6E4E1',
      neutralLight5: '#DBD8D5',
      neutralDark1: '#000000',
      primaryDark: '#313D4A',
      primaryLight: '#99854F',
      danger: '#CC5858',
    },
  },
  fontFamily: {
    heading: "'beaufort-pro', serif",
    paragraph: "'neuzeit-grotesk', serif",
    highlight: "'old-standard', serif",
  },
}

export type Styles = typeof styles

export const styles = {
  h1: css`
    font-size: clamp(120px, 8.3vw, 150px);
    font-weight: 300;
    line-height: 0.9;
    white-space: nowrap;
    text-transform: uppercase;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.neutralLight1};

    @media (max-width: 991px) {
      line-height: 1;
      font-size: 52px;
    }
  `,
  h2: css`
    line-height: 1;
    font-weight: 300;
    font-size: 4.1vw;
    text-transform: uppercase;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.primaryDark};

    @media (max-width: 991px) {
      font-size: 36px;
    }
  `,
  h3: css`
    line-height: 1;
    font-weight: 300;
    font-size: clamp(36px, 3.33vw, 3.33vw);
    text-transform: uppercase;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.primaryDark};
  `,
  h4: css`
    line-height: 1;
    font-weight: 300;
    font-size: clamp(24px, 2.5vw, 2.5vw);
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.primaryDark};
  `,

  label: css`
    letter-spacing: 0.1vw;
    font-weight: 400;
    text-transform: uppercase;
    font-size: clamp(16px, 1.11vw, 24px);
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.primaryLight};
  `,
  labelSmall: css`
    font-weight: 200;
    font-size: clamp(12px, 0.9vw, 18px);
    line-height: 1;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: ${theme.fontFamily.paragraph};
    color: ${theme.colors.variants.primaryDark};
  `,
  description: css`
    font-weight: 300;
    font-size: clamp(18px, 1.25vw, 21px);
    line-height: 1.5;
    font-family: ${theme.fontFamily.paragraph};
    color: ${theme.colors.variants.primaryDark};
  `,
  highlight: css`
    font-weight: 500;
    font-size: clamp(20px, 1.6vw, 28px);
    line-height: 1.5;
    font-family: ${theme.fontFamily.highlight};
    color: ${theme.colors.variants.primaryDark};
  `,
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  const isBrowser = typeof window !== 'undefined'

  let smoothScroll = false
  if (isBrowser) smoothScroll = window.innerWidth > 991 ? true : false

  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {smoothScroll ? (
          <ReactLenis root options={{ duration: 1.4 }}>
            {children}
          </ReactLenis>
        ) : (
          <>{children}</>
        )}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
